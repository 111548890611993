import React from 'react'
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"


import './index.scss'

const BlogAuthorCard = ({authorTitle, authorName, authorProfileImage}) => (
    <div className="blog-author-card">
        <StaticImage placeholder="blurred"
            className="blog-author-card__left-background-image"
            src="../../../static/assets/marketing-site/images/author-background-2.png"
            alt="background"
            objectFit='cover'
            objectPosition='right'
            
        />
        <div className="blog-author-card__main-content">
            <GatsbyImage
                image={authorProfileImage}
                alt="Blog Post Author"
                className="blog-author-card__author-image"
                objectFit='contain'
            />
            <h2 className="blog-author-card__author-name">{authorName}</h2>
            <p className="blog-author-card__author-title">{authorTitle}</p>
        </div>
        <StaticImage placeholder="blurred"
            className="blog-author-card__right-background-image"
            src="../../../static/assets/marketing-site/images/author-background-1.png"
            alt="background"
            objectFit='cover'
            objectPosition='left'
        />
    </div>
)

export default BlogAuthorCard