import React, {useState} from "react"
import { graphql } from "gatsby"
// import Layout from "../components/layout"
// import SEO from "../components/seo"

import { Helmet } from "react-helmet";
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { LinkWithUTM as Link } from "../components/LinkWithUTM";
import FacebookIcon from '../components/icons/Facebook'
import LinkedinIcon from '../components/icons/Linkedin'
import MailIcon from '../components/icons/Mail'
import TwitterIcon from '../components/icons/Twitter'
import BlogAuthorCard from '../components/BlogAuthorCard'
import { 
	Newsblock,
	NewsblockSection,
	NewsblockContent,
	NewsblockBlocks,
	NewsblockButton,
	NewsblockTitle,
} from '../components/Newsblock';
import { CarouselComponent, CarouselComponentSlide } from '../components/carousel';


import "../../scss/main.scss";
import "../../scss/_blog.scss";

export default function Template(props) {

	const {data} = props
	const {pageContext : { feature_image }} = props

    const { ghostPost: { primary_author, title, excerpt, published_at, feature_image_sharp, slug, html_sections}, relatedPosts } = data

	const [isAllRelatedBlogsVisible, setIsAllRelatedBlogsVisible] = useState(false)
	const allNewsblockData = relatedPosts.nodes.filter(({slug: relatedPostSlug}) => relatedPostSlug !== slug)
	
	const newsblockData = isAllRelatedBlogsVisible ? allNewsblockData : allNewsblockData.slice(0, 3)

    return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | {title}</title>
				<meta property="og:title" content={`Edyn Care | ${title}`}/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content={feature_image}/>
				<meta property="og:url" content={`https://www.edyn.care/${props.path}`} />

				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content={`https://www.edyn.care/${props.path}`} />
				<meta name="twitter:title" content={`Edyn Care | ${title}`} />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content={feature_image} />
			</Helmet>
			<div className="container">
				<Header />
				<main className="blog-post">
					<div className="blog-post__above-the-fold">
						<StaticImage
							src="../../static/assets/marketing-site/images/our-approach-01.png"
							objectFit='contain'
							placeholder= "blurred"
							loading="eager"
						/>
						<div>
							<h1 className="blog-post__above-the-fold-title">{title}</h1>
							<p className="blog-post__above-the-fold-published">{published_at}</p>
						</div>
						<StaticImage
							src="../../static/assets/marketing-site/images/our-approach-02.png"
							objectFit='contain'
							placeholder= "blurred"
							loading="eager"
						/>
					</div>
					<GatsbyImage
						className="blog-post__hero-image"
						image={getImage(feature_image_sharp)}
						alt="Blog post image"
						objectFit="cover"
					/>
					<section className="blog-post-section">
						<div className="blog-post-entry">
							{/* <div>
								<GatsbyImage
									image={getImage(feature_image_sharp)}
									alt="Blog post image"
								/>
							</div> */}
							{excerpt && (
								<div className="blog-post-entry__content">
									<h3 className="secondary">
										Deciding as a family whether live-in care is the right option
										for a loved one is not always easy. You may not have consensus
										on what the best way forward is, or not know which option your
										loved one would prefer. After all, care and support is not
										something we arrange every day!
									</h3>
								</div>
							)}
						</div>

						<div className="blog-post__content">
							{html_sections.json.filter(
								({tagName}) => tagName !== null
							).map(({tagName, innerHtml, attributes}) => {
								if (attributes && attributes.data_edyn_component === "primary-button") {
									if (attributes.data_edyn_url.startsWith("tel:")) return (
										<div className="blog-button-wrapper">
											<a href={attributes.data_edyn_url} className="btn-primary">
												{innerHtml}
											</a>
										</div>
									)
									return (
										<div className="blog-button-wrapper">
											<Link to={attributes.data_edyn_url} className="btn-primary">
												{innerHtml}
											</Link>
										</div>
									)
								}
								const HtmlTag = tagName
								if(tagName.toLowerCase() === "hr") return <hr />
								return <HtmlTag id={attributes.id} className={attributes.class} dangerouslySetInnerHTML={{__html: innerHtml}} />
							})}
						</div>

						<div className="blog-post__socials">
							<a href="https://www.facebook.com/EdynCare/">
								<FacebookIcon className="social-icon"/>
							</a>
							<a href="https://twitter.com/EdynCare">
								<TwitterIcon className="social-icon"/>
							</a>
							<a href="https://www.linkedin.com/company/edyn-care/">
								<LinkedinIcon className="social-icon" />
							</a>
							<a href="mailto:hello@edyn.care">
								<MailIcon className="social-icon"/>
							</a>
						</div>

						<BlogAuthorCard
							authorTitle={primary_author.bio}
							authorName={primary_author.name}
							authorProfileImage={getImage(primary_author.profile_image_sharp)}
						/>
					</section>
					{allNewsblockData && allNewsblockData.length > 0 && (
						<>
							<hr/>
							<NewsblockSection title="Related posts" className="newsblock-desktop newsblock-latest">
								<NewsblockContent>
									<NewsblockTitle>
										Related posts
									</NewsblockTitle>
									<NewsblockBlocks>
										{newsblockData.map(({published_at, excerpt, title, feature_image_sharp, slug}) => (
											<Newsblock
												color="yellow"
												date={published_at}
												title={title}
												description={
													excerpt.length > 220
														? excerpt.slice(0, excerpt.indexOf(' ', 220)) + ' ...'
														: excerpt}
												link={`/blog/${slug}/`}>
												<GatsbyImage placeholder="blurred" image={getImage(feature_image_sharp)} />
											</Newsblock>
										))}
									</NewsblockBlocks>
								</NewsblockContent>
								{!isAllRelatedBlogsVisible && allNewsblockData.length > 3 && (
									<NewsblockButton onClick={() => {
										setIsAllRelatedBlogsVisible(true)
									}}>
										Load more
									</NewsblockButton>
								)}
							</NewsblockSection>

							<NewsblockSection title="Related posts" className="newsblock-mobile">
								<NewsblockContent className='with-image'>
									<NewsblockTitle>
										Related posts
									</NewsblockTitle>
									<NewsblockBlocks>
										<CarouselComponent>
											{newsblockData.map(({published_at, excerpt, title, feature_image_sharp, slug}) => (
													<CarouselComponentSlide>
														<Newsblock
															className='with-image'
															color="yellow"
															date={published_at}
															title={title}
															description={excerpt}
															link={`/blog/${slug}/`}>
															<GatsbyImage placeholder="blurred" image={getImage(feature_image_sharp)} className='with-image'/>
														</Newsblock>
													</CarouselComponentSlide>
											))}
										</CarouselComponent>
									</NewsblockBlocks>
								</NewsblockContent>
								{!isAllRelatedBlogsVisible && allNewsblockData.length > 3 && (
									<NewsblockButton onClick={() => {
										setIsAllRelatedBlogsVisible(true)
									}}>
										Load more
									</NewsblockButton>
								)}
							</NewsblockSection>
						</>
					)}
				</main>
				<Footer />
			</div>
		</>
	);
}

export const pageQuery = graphql`
  	query($slug: String!, $tagSlug: String!) {
		ghostPost(slug: { eq: $slug }) {
			html
			html_sections {
				json {
					tagName
					innerHtml
					attributes {
						data_edyn_component
						data_edyn_url
						class
						id
					}
				}
			}
			title
			primary_tag {
				name
			}
			slug
			published_at(formatString: "DD MMM YYYY")
			feature_image_sharp {
				childImageSharp {
					gatsbyImageData
				}
			}
			primary_author {
				bio
				name
				profile_image_sharp {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
		relatedPosts: allGhostPost(filter: {primary_tag: {slug: { eq: $tagSlug}}}) {
			nodes {
				title
				slug
				feature_image_sharp {
					childImageSharp {
			    		gatsbyImageData
					}
				}
			  excerpt
			  published_at(formatString: "DD MMM YYYY")
			}
		  }
  	}
`